<template>
  <div>
    <add-modal
      v-if="showModal"
      v-model="showModal"
      :edited-item="editedItem"
      :show-modal="v => { showModal = v }"
      @update="load"
    />

    <sw-table
      :table-id="`${status}_7223a51eca4545e3aca82cdfeb71ecda`"
      show-columns
      show-search
      :fields="fieldsList[status]"
      :pagination="table.pagination"
      @change-pagination="Object.assign(table.pagination, $event)"
      @change-search-query="searchQuery = $event"
      @reload-content="load()"
      @set-fields="fieldsList[status] = $event"
    >
      <template #table>
        <!--      Table      -->
        <b-table
          :items="items"
          class="offer-table"
          :fields="fields"
          striped
          responsive
          :busy="isLoading"
          show-empty
        >
          <!--    Head    -->
          <template #head()="{ label }">
            {{ $t(`${label}`) }}
          </template>

          <!--    Preview    -->
          <template #cell(preview)="{ item }">
            <b-button
              variant="flat-primary"
              size="sm"
              @click="showPreviewModal = item"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </template>

          <!--    Shipped    -->
          <template #cell(shipped)="{ value }">
            <feather-icon
              :icon="value ? 'CheckSquareIcon' : 'XSquareIcon'"
            />
          </template>

          <!--    NotDelivered    -->
          <template #cell(delivered)="{ value }">
            <feather-icon
              :icon="value ? 'CheckSquareIcon' : 'XSquareIcon'"
            />
          </template>

          <!--    Recipients Message    -->
          <template #cell(recipientsMessage)="{ item }">
            <b-badge
              variant="primary"
              class="cursor-pointer"
              @click="showRecipientModal = item"
            >
              {{ $t('Details') }}
            </b-badge>
          </template>

          <!--    Recipients Number    -->
          <template #cell(generatedTargetContacts)="{ item }">
            <b-badge variant="light-primary">
              {{ item.campaignTargets.length || 0 }}
            </b-badge>
          </template>

          <!--    Sent    -->
          <template #cell(sent)="{ value }">
            <b-badge variant="light-primary">
              {{ value }}
            </b-badge>
          </template>

          <!--    Opened    -->
          <template #cell(opened)="{ value }">
            <b-badge variant="light-primary">
              {{ value }}
            </b-badge>
          </template>

          <!--    Created By    -->
          <template #cell(createdBy)="{ value }">
            <avatar
              :user="value"
              :to="{ name: 'user', params: { userId: value.id } }"
            />
          </template>

          <!-- Created At -->
          <template #cell(createdAt)="{ value }">
            {{ formatDate(value.date, { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}
          </template>

          <!-- Start At -->
          <template #cell(startAt)="{ value }">
            {{ formatDate(value.date, { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}
          </template>

          <!--     Action     -->
          <template #cell(action)="{ item }">
            <b-dropdown
              id="dropdown-dropleft"
              dropleft
              no-caret
              variant="flat"
            >
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" />
              </template>

              <b-dropdown-item
                v-if="status === 'DRAFT'"
                @click="editedItem = item; showModal = true"
              >
                <feather-icon
                  class="mr-25"
                  icon="Edit2Icon"
                />

                {{ $t('edit') }}
              </b-dropdown-item>

              <b-dropdown-item
                v-if="status === 'DRAFT'"
                @click="sendItem(item.id)"
              >
                <feather-icon
                  class="mr-25"
                  icon="SendIcon"
                />

                {{ $t('Send') }}
              </b-dropdown-item>

              <b-dropdown-item
                v-if="['PLANNED'].includes(status)"
                @click="cancelItem(item.id)"
              >
                <feather-icon
                  class="mr-25"
                  icon="XIcon"
                />

                {{ $t('Cancel') }}
              </b-dropdown-item>

              <b-dropdown-item
                v-if="['DRAFT', 'PLANNED'].includes(status)"
                @click="deleteItem(item.id)"
              >
                <feather-icon
                  class="mr-25"
                  icon="TrashIcon"
                />

                {{ $t('Remove') }}
              </b-dropdown-item>
            </b-dropdown>
          </template>

          <!--    Other    -->
          <!--    Other    -->
          <template #cell()="row">
            <table-default-cell
              :field="row.field"
              :value="row.value"
            />
          </template>

          <!--      Empty      -->
          <template #empty>
            <empty-content />
          </template>

          <!--      Table Busy      -->
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner
                class="align-middle"
                variant="primary"
              />
            </div>
          </template>
        </b-table>
      </template>
    </sw-table>

    <b-modal
      :title="$t('Details')"
      size="lg"
      static
      :visible="Boolean(showPreviewModal)"
      hide-footer
      @change="v => { showPreviewModal = v }"
    >
      <template #modal-title>
        <div
          v-if="showPreviewModal"
          class="d-flex flex-wrap align-items-center"
          style="column-gap: .5rem"
        >
          {{ $t('Preview') }}

          <feather-icon icon="ArrowRightIcon" />

          <p
            v-b-tooltip.hover.v-primary
            class="text-primary mb-0"
            :title="$t(`DateOfShipping`)"
          >
            {{ formatDate(showPreviewModal.startAt.date, { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}
          </p>
        </div>
      </template>

      <preview-modal
        v-if="showPreviewModal"
        v-model="showPreviewModal"
      />
    </b-modal>

    <b-modal
      :title="$t('Details')"
      size="lg"
      static
      :visible="Boolean(showRecipientModal)"
      hide-footer
      @change="v => { showRecipientModal = v }"
    >
      <template #modal-title>
        <div
          class="d-flex flex-wrap align-items-center"
          style="column-gap: .5rem"
        >
          {{ $t('Details') }}
        </div>
      </template>

      <recipients-modal
        v-if="showRecipientModal"
        v-model="showRecipientModal"
      />
    </b-modal>
  </div>
</template>

<script>
import { BTable, VBTooltip } from 'bootstrap-vue'
import { DELETE_CAMPAIGNS, GET_CAMPAIGNS, UPDATE_CAMPAIGNS } from '@/@constants/mutations'
import { formatDate } from '@core/utils/filter'
import AddModal from '@/pages/campaigns/mail/Add.vue'
import RecipientsModal from './modals/recipients/RecipientsViewModal.vue'
import PreviewModal from './modals/PreviewModal.vue'

export default {
  components: {
    AddModal,
    BTable,
    RecipientsModal,
    PreviewModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    update: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    isLoading: false,
    showModal: false,
    editedItem: null,

    items: [],
    fieldsList: {
      DRAFT: [
        {
          key: 'id', label: '#', visible: true, sortable: true,
        },
        { key: 'preview', label: 'Preview', visible: true },
        { key: 'createdAt', label: 'CreatedAt', visible: true },
        { key: 'name', label: 'CampaignName', visible: true },
        { key: 'recipientsMessage', label: 'RecipientsMessage', visible: true },
        { key: 'generatedTargetContacts', label: 'RecipientsNumber', visible: true },
        { key: 'createdBy', label: 'CreatedBy', visible: true },
        { key: 'startAt', label: 'PlannedShippingDate', visible: true },
        { key: 'action', label: 'Action', visible: true },
      ],
      RUNNING: [
        {
          key: 'id', label: '#', visible: true, sortable: true,
        },
        { key: 'preview', label: 'Preview', visible: true },
        { key: 'createdAt', label: 'CreatedAt', visible: true },
        { key: 'name', label: 'CampaignName', visible: true },
        { key: 'recipientsMessage', label: 'RecipientsMessage', visible: true },
        { key: 'generatedTargetContacts', label: 'RecipientsNumber', visible: true },
        // { key: 'sent', label: 'Sended', visible: true },
        // { key: 'shipped', label: 'CompaniesStatus.NotSend', visible: true },
        // { key: 'delivered', label: 'CompaniesStatus.NotDelivered', visible: true },
        { key: 'createdBy', label: 'CreatedBy', visible: true },
        { key: 'startAt', label: 'PlannedShippingDate', visible: true },
        // { key: 'action', label: 'Action', visible: true },
      ],
      PLANNED: [
        {
          key: 'id', label: '#', visible: true, sortable: true,
        },
        { key: 'preview', label: 'Preview', visible: true },
        { key: 'createdAt', label: 'CreatedAt', visible: true },
        { key: 'name', label: 'CampaignName', visible: true },
        { key: 'recipientsMessage', label: 'RecipientsMessage', visible: true },
        { key: 'generatedTargetContacts', label: 'RecipientsNumber', visible: true },
        { key: 'createdBy', label: 'CreatedBy', visible: true },
        { key: 'startAt', label: 'PlannedShippingDate', visible: true },
        { key: 'action', label: 'Action', visible: true },
      ],
      SUCCESS: [
        {
          key: 'id', label: '#', visible: true, sortable: true,
        },
        { key: 'preview', label: 'Preview', visible: true },
        { key: 'createdAt', label: 'CreatedAt', visible: true },
        { key: 'statusUpdatedAt', label: 'DateOfShipping', visible: true },
        { key: 'name', label: 'CampaignName', visible: true },
        { key: 'recipientsMessage', label: 'RecipientsMessage', visible: true },
        { key: 'generatedTargetContacts', label: 'RecipientsNumber', visible: true },
        // { key: 'sent', label: 'Sended', visible: true },
        // { key: 'shipped', label: 'CompaniesStatus.NotSend', visible: true },
        // { key: 'delivered', label: 'CompaniesStatus.NotDelivered', visible: true },
        { key: 'createdBy', label: 'CreatedBy', visible: true },
        { key: 'startAt', label: 'PlannedShippingDate', visible: true },
        // { key: 'action', label: 'Action', visible: true },
      ],
      FAILED: [
        {
          key: 'id', label: '#', visible: true, sortable: true,
        },
        { key: 'preview', label: 'Preview', visible: true },
        { key: 'createdAt', label: 'CreatedAt', visible: true },
        { key: 'statusUpdatedAt', label: 'DateOfShipping', visible: true },
        { key: 'name', label: 'CampaignName', visible: true },
        { key: 'recipientsMessage', label: 'RecipientsMessage', visible: true },
        { key: 'generatedTargetContacts', label: 'RecipientsNumber', visible: true },
        // { key: 'sent', label: 'Sended', visible: true },
        // { key: 'shipped', label: 'CompaniesStatus.NotSend', visible: true },
        // { key: 'delivered', label: 'CompaniesStatus.NotDelivered', visible: true },
        { key: 'createdBy', label: 'CreatedBy', visible: true },
        { key: 'startAt', label: 'PlannedShippingDate', visible: true },
        // { key: 'action', label: 'Action', visible: true },
      ],
    },

    table: {
      sorter: {
        sortBy: 'createdAt',
        sortDesc: true,
      },
      pagination: {
        perPage: 25,
        totalRecords: 0,
        currentPage: 1,
      },
    },
    searchQuery: '',

    showPreviewModal: false,
    showRecipientModal: false,
  }),

  computed: {
    fields() { return this.fieldsList[this.status].filter(field => field.visible) },
  },

  watch: {
    async update() {
      await this.load()
    },
  },

  mounted() { this.load() },

  methods: {
    formatDate,

    async load() {
      this.isLoading = true

      const payload = {
        eq_type: 'EMAIL',
        eq_status: this.status,
        page: this.table.pagination.currentPage,
        limit: this.table.pagination.perPage,
      }

      if (this.searchQuery.length) {
        const search = this.searchQuery.replaceAll(' ', '%')
        payload.search_fields = 'name'
        payload.search = `%${search}%`
      }

      const {
        items,
        totalItemCount,
        currentPageNumber,
        itemNumberPerPage,
      } = await this.$store.dispatch(`campaigns/${GET_CAMPAIGNS}`, payload)

      this.items = items
      this.table.pagination = {
        perPage: itemNumberPerPage,
        totalRecords: totalItemCount,
        currentPage: currentPageNumber,
      }

      this.isLoading = false
    },

    async sendItem(id, index) {
      this.isLoading = true

      const payload = {
        id,
        status: 'PLANNED',
      }

      this.$store.dispatch(`campaigns/${UPDATE_CAMPAIGNS}`, payload)
        .then(() => {
          this.showToast('success', this.$i18n.t('task.Labels.AddedNewMail'))
          this.items.splice(index, 1)
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

      this.isLoading = false
    },

    async cancelItem(id, index) {
      this.isLoading = true

      const payload = {
        id,
        status: 'DRAFT',
      }

      this.$store.dispatch(`campaigns/${UPDATE_CAMPAIGNS}`, payload)
        .then(() => {
          this.showToast('success', this.$i18n.t('Canceled'))
          this.items.splice(index, 1)
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

      this.isLoading = false
    },

    async deleteItem(id) {
      this.isLoading = true

      this.$store.dispatch(`campaigns/${DELETE_CAMPAIGNS}`, id)
        .then(() => {
          this.showToast('success', this.$i18n.t('alerts.Removed'))
          this.load()
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

      this.isLoading = false
    },
  },
}
</script>
