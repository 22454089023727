<template>
  <div
    class="d-flex flex-column"
    style="row-gap: 1.34rem"
  >
    <b-alert
      show
      variant="primary"
      class="px-75 py-1 mb-0 d-flex align-items-center justify-content-between"
    >
      <div class="d-flex align-items-center">
        <b-avatar variant="primary">
          <feather-icon icon="UserIcon" />
        </b-avatar>

        <span class="d-flex flex-column ml-75">
          <strong>
            {{ $t('Sender') }}
          </strong>

          <span>
            {{ value.userMailbox.name }}
            ({{ value.userMailbox.imapHost }})
          </span>
        </span>
      </div>
    </b-alert>

    <div>
      <h5 class="text-primary mb-25">
        {{ $t('Message') }}:
      </h5>

      <p class="d-flex flex-column">
        <strong>{{ value.subject }}</strong>

        <span v-html="value.content" />
      </p>

        <p class="d-flex flex-column">
        <strong>{{ $t('Footer') }}</strong>

        <span v-html="value.footer" />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
}
</script>
